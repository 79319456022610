<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Modifica tipologia di vino {{ wineType ? wineType.name : '' }}</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'inventory.wine-types' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <WineTypeForm v-if="wineType" :value="wineType" :feedback.sync="feedback" @submitForm="saveWineType" edit />
    </div>
  </main>
</template>

<script>

import $api from '@/libs/Api/services/api.js';

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import WineTypeForm from '@/views/components/Form/WineTypeForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    WineTypeForm,
  },
  data () {
    return {
      wineType: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'inventory.wine-types' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveWineType (wineTypeForm) {
      const wineType = { ...wineTypeForm };

      this.$api.updateWineType(wineType.id, wineType)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
  async beforeRouteEnter (to, from, next) {
    $api.fetchWineType(to.params.id)
      .then(res => {
        return next(vm => {
          vm.wineType = res?.data || {};
        });
      })
      .catch(err => {
        return next(vm => vm.$log.error(err));
      })
    ;
  },
};

</script>
